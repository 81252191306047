import Vue from 'vue';
import Router from 'vue-router';
import { isMobile } from './util'

Vue.use(Router);
const routes = [
  {
    path: '*',
    redirect: isMobile() ? '/m' : '/index'
  },
  {
    path: '/',
    component: () => import('./pages/index'),
  },
  {
    path: '/m',
    component: () => import('./pages/h5'),
  },
];


// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes, mode: 'history' });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};