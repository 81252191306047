import Vuex from 'vuex';
import Vue from 'vue';
import common from './modules/common'

Vue.use(Vuex);
export default new Vuex.Store({
	strict: true,
	state: {},
	modules: {
		common,
	}
})