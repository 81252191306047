import Vue from 'vue'
import App from './App.vue'
import Lazy from 'vue-lazyload'
import store from './store';
import { router } from './router';
import { isMobile } from './util'

Vue.config.productionTip = false

Vue.use(Lazy, {
  preLoad: 1.3,
  attempt: 1,
})

Vue.prototype.$isMobile = isMobile();

Vue.prototype.$isIos = () => {
  return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
}

new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App),
}).$mount('#app')
