export default {
  namespaced: true,
  state: {
    // locale: i18n.locale,
    // supports: ['zh', 'en'],
  },
  mutations: {
    channgeLocale(state, locale) {
      state.locale = locale;
    }
  },
  actions:{
    changeLocale({state, commit}, locale) {
      if(!locale || state.supports.indexOf(locale) < 0) {
        return false;
      }
      localStorage.setItem('locale',locale);
      // i18n.locale = locale;
      commit('channgeLocale', locale);
      return true
    }
  }
}