import * as THREE from "three";
// import { OrbitControls } from "three-stdlib";
import { DRACOLoader } from "three-stdlib/loaders/DRACOLoader";
import { GLTFLoader } from "three-stdlib/loaders/GLTFLoader";
import { RoomEnvironment } from "three-stdlib/environments/RoomEnvironment";

const loadGlbModel = async (
  canvas,
  modelName,
  _camera = { x: 10, y: 0, z: 0 },
  isAnim = false,
  _sizes = {},
  pointLight = false
) => {
  const gltfLoader = new GLTFLoader();
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath(`${process.env.BASE_URL}draco/`);

  dracoLoader.preload();
  gltfLoader.setDRACOLoader(dracoLoader);

  const sizes = { width: 1440, height: 800, ..._sizes };

  const axes = new THREE.AxesHelper();
  const scene = new THREE.Scene();
  const group = await gltfLoader.loadAsync(`${process.env.BASE_URL}model/${modelName}`);

  scene.add(group.scene);

  const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
  (camera.position.x = _camera.x), (camera.position.y = _camera.y), (camera.position.z = _camera.z);
  camera.lookAt(axes.position);
  if (pointLight) {
    pointLight.forEach((lightAttr) => {
      const light = new THREE.PointLight(
        lightAttr.color,
        lightAttr.intensity,
        lightAttr.distance,
        lightAttr.decay
      );
      light.position.set(lightAttr.pos.x, lightAttr.pos.y, lightAttr.pos.z);
      scene.add(light);
    });
  }
  // const controls = new OrbitControls(camera, canvas);
  // controls.enablePan = false; //禁止平移
  // controls.enableZoom = false;
  const renderer = new THREE.WebGLRenderer({ canvas, alpha: true, antialias: true });

  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  const environment = new RoomEnvironment();
  const pmremGenerator = new THREE.PMREMGenerator(renderer);
  scene.environment = pmremGenerator.fromScene(environment).texture;

  function animate() {
    requestAnimationFrame(animate);
    // update();
    renderer.render(scene, camera);
    if (isAnim) {
      scene.rotateY(0.005);
    }
  }
  animate();

  // const handleWindowResize = () => {
  //   sizes.width = window.innerWidth;
  //   sizes.height = window.innerHeight;

  //   // update camera
  //   camera.aspect = sizes.width / sizes.height;
  //   camera.updateProjectionMatrix();

  //   // update renderer
  //   renderer.setSize(sizes.width, sizes.height);
  //   renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  // };

  // handleWindowResize();
  // window.addEventListener("resize", handleWindowResize);
};
const scrollToY = function(y, duration = 0, element = document.scrollingElement) {
  // cancel if already on target position
  if (element.scrollTop === y) {
    return;
  }

  const NUMBER_TWO = 2;
  const cosParameter = (element.scrollTop - y) / NUMBER_TWO;
  let scrollCount = 0;
  let oldTimestamp = null;

  const step = function(newTimestamp) {
    if (oldTimestamp !== null) {
      // if duration is 0 scrollCount will be Infinity
      scrollCount += (Math.PI * (newTimestamp - oldTimestamp)) / duration;
      if (scrollCount >= Math.PI) {
        element.scrollTop = y;
        return;
      }
      element.scrollTop = cosParameter + y + cosParameter * Math.cos(scrollCount);
    }
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  };
  window.requestAnimationFrame(step);
};

const isMobile = () => {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}

export { loadGlbModel, scrollToY, isMobile };
